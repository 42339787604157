import { Link as ScrollLink } from 'react-scroll';
import { useLocation, Outlet } from 'react-router-dom';
// material
import { Box, Link, Container, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
// components
import Logo from '../../components/Logo';
//
import MainNavbar from './MainNavbar';
import MainFooter from './MainFooter';

// ----------------------------------------------------------------------

export default function MainLayout() {
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  const { pathname } = useLocation();
  const isHome = pathname === '/';
  const theme = useTheme();

  return (
    <>
      <MainNavbar />
      <div>
        <Outlet />
      </div>
      {/* <!--Start of Tawk.to Script--> */}
      {/* <script type="text/javascript">
      var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
      (function(){
      var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
      s1.async=true;
      s1.src='https://embed.tawk.to/6183bd5e6bb0760a49411896/1fjl9nep6';
      s1.charset='UTF-8';
      s1.setAttribute('crossorigin','*');
      s0.parentNode.insertBefore(s1,s0);
      })();
      </script> */}
{/* <!--End of Tawk.to Script--> */}

        {/* <MainFooter /> */}

        <Box
          sx={{
            py: 3,
            textAlign: 'center',
            position: 'relative',
            backgroundColor: '##052F5F',
            ...(isMobile && {pb:5})
          }}
        >
          <Container maxWidth="lg">

          <Typography variant="h5" sx={{mb:1, color: 'common.white', zIndex: 101}}>
              “Intelligence is the ability to avoid doing work, yet getting the work done”
            </Typography>

            <Typography variant="caption" component="p" sx={{color: 'common.white'}}>
              © All rights reserved. {isMobile && <br/>} Millionic (Pvt) Ltd.
              {/* <br /> made by &nbsp;
              <Link href="https://minimals.cc/">minimals.cc</Link> */}
            </Typography>
          </Container>
        </Box>
      
    </>
  );
}
