import PropTypes from 'prop-types';
// material
import { useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
  comingFrom: PropTypes.string,
  offSet: PropTypes.bool,
};

export default function Logo({ sx, comingFrom, offSet }) {
  // const theme = useTheme();
  // const PRIMARY_LIGHT = theme.palette.primary.light;
  // const PRIMARY_MAIN = theme.palette.primary.main;
  // const PRIMARY_DARK = theme.palette.primary.dark;

  const DARK_LOGO = "/static/brand/MillionicLogo.png"
  const LIGHT_LOGO = "/static/brand/LOGOWHITE.png"

  let logoPath = DARK_LOGO;

  if(comingFrom === "navbar" && !offSet){
    logoPath = LIGHT_LOGO
  }

  return (
    <>
      <img src={logoPath} alt="main logo" style={{ height: 40, width: 'auto' }} />
    </>
  );
}
