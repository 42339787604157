import { useState, useRef } from "react";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
// material
import { experimentalStyled as styled } from "@material-ui/core/styles";
import {
  Box,
  Button,
  AppBar,
  Toolbar,
  Container,
  List,
  MenuItem,
  ListItemIcon,
  Icon,
  ListItemText,
  Link,
} from "@material-ui/core";
// hooks
import useOffSetTop from "../../hooks/useOffSetTop";
// components
import Logo from "../../components/Logo";
import Label from "../../components/Label";
import { MHidden, MIconButton } from "../../components/@material-extend";
//MenuICon
import menu2Fill from "@iconify/icons-eva/menu-2-fill";

//
import MenuDesktop from "./MenuDesktop";
import MenuMobile from "./MenuMobile";
import navConfig from "./MenuConfig";
import MenuPopover from "src/components/MenuPopover";
import AnchorLink from "react-anchor-link-smooth-scroll";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 88;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(["height", "background-color"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up("md")]: {
    height: APP_BAR_DESKTOP,
  },
}));

const ToolbarShadowStyle = styled("div")(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: "auto",
  borderRadius: "50%",
  position: "absolute",
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8,
}));

const BoxStyle = styled(Box)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.primary,
  marginRight: theme.spacing(5),
  textDecoration: "none",
  transition: theme.transitions.create("opacity", {
    duration: theme.transitions.duration.shortest,
  }),
  "&:hover": {
    opacity: 0.48,
    textDecoration: "none",
  },
}));

// ----------------------------------------------------------------------

export default function MainNavbar() {
  const anchorRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  const offset = useOffSetTop(20);

  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  const isOffset = useOffSetTop(100);
  const { pathname } = useLocation();
  const isHome = pathname === "/";

  return (
    <AppBar color={isHome ? "transparent" : "default"} sx={{ boxShadow: 0 }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: "background.default",
            height: { md: APP_BAR_DESKTOP - 16 },
          }),
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <RouterLink to="/">
            <Logo comingFrom="navbar" offSet={isOffset} />
          </RouterLink>
          <Box sx={{ flexGrow: 1 }} />

          <MHidden width="mdDown">
            <MenuDesktop
              isOffset={isOffset}
              isHome={isHome}
              navConfig={navConfig}
            />
          </MHidden>

          {!isMobile && (
            <Button
              sx={{
                ...(!isOffset && {
                  backgroundColor: "white",
                  color: "#052F5F",
                  '&:hover': {color: 'common.white'}
                }),
              }}
              variant="contained"
              onClick={() => window.Tawk_API.toggle()}
            >
              Chat Now
            </Button>
          )}

          <MHidden width="mdUp">
            <MenuMobile
              isOffset={isOffset}
              isHome={isHome}
              navConfig={navConfig}
            />
            {/* <MIconButton
              ref={anchorRef}
              onClick={() => setOpenMenu(true)}
              sx={{
                ml: 1,
                // ...(special && { color: "common.white" }),
                ...(offset && { color: "common.white" }),
              }}
            >
              <Icon icon={menu2Fill} sx={{ color: "common.white" }} />
            </MIconButton> */}
            {/* <MenuPopover
              disablePortal
              open={openMenu}
              anchorEl={anchorRef.current}
              onClose={() => setOpenMenu(false)}
            >
              <List>
                {navConfig.map((link) => (
                  <BoxStyle
                    exact
                    href={link.path}
                    // to={link.path}
                    key={link.title}
                    component={AnchorLink}
                    onClick={() => setOpenMenu(false)}
                    // activeClassName="isMobileActive"
                    sx={{ color: "text.primary" }}
                    //
                  >
                    <ListItemIcon>
                      <Icon icon={link.icon} width={20} height={20} />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ typography: "body2" }}
                    >
                      {link.title}
                    </ListItemText>
                  </BoxStyle>
                ))}
              </List>
            </MenuPopover> */}
          </MHidden>
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
