import { Icon } from "@iconify/react";
import homeFill from "@iconify/icons-eva/home-fill";
import fileFill from "@iconify/icons-eva/file-fill";
import serviceIcon from "@iconify/icons-eos-icons/service";
import infoSquareFill from "@iconify/icons-bi/info-square-fill";
import roundHelpCenter from "@iconify/icons-ic/round-help-center";
import contactCard16Filled from "@iconify/icons-fluent/contact-card-16-filled";
// routes
import { PATH_DASHBOARD } from "../../routes/paths";

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: "Home",
    path: "#move_top",
    icon: <Icon icon={homeFill} {...ICON_SIZE} />,
  },
  {
    title: "Services",
    path: "#services",
    icon: <Icon icon={serviceIcon} {...ICON_SIZE} />,
  },
  {
    title: "About Us",
    path: "#about",
    icon: <Icon icon={infoSquareFill} {...ICON_SIZE} />,
  },
  {
    title: "Contact Us",
    path: "#contact",
    icon: <Icon icon={contactCard16Filled} {...ICON_SIZE} />,
  },
  {
    title: "FAQ",
    path: "#faq",
    icon: <Icon icon={roundHelpCenter} {...ICON_SIZE} />,
  },
];

export default menuConfig;
